import React from 'react';
import MissionchiefBotExample from '../Media/Gifs/MissionchiefBotExample.gif'

const GifComponent = () => {
    return (
      <div>
        <img src={MissionchiefBotExample} alt="Example of Missionchief Bot In Action" />
      </div>
    );
  };
  
  export default GifComponent;
  